<template>
    <div>
        <competition-header
          v-model="currentCompetition" :showBackButton="false">
        </competition-header>
        <server-not-responding>
          <local-data-only-info-panel></local-data-only-info-panel>
        </server-not-responding>
        <b-container fluid>
          <b-button @click="launch('CompetitionEdit')" style="width:100%; height:3rem;" ><b-icon icon="pencil"/> Configuration de la compétition</b-button>

          <collapse-panel v-for="round in currentCompetition.ROUNDS" :variant="round.isCompleted && round.isDebriefingCompletedOrSkipped ? 'success' : ( round.isStarted ? 'warning' : (!round.isReadyToBriefing ? 'danger' : ''))/*{'success': round.isCompleted, 'warning': round.isStarted && !round.isCompleted}*/" :visible="!round.isCompleted && round.isReadyToProcess" :key="round.round_number" :title="'Tour/Manche' + round.round_number" lazy>
            <round-launcher  
              :round="round"
              :waitMode="d_waitMode"
              @startWaitingForEvent = "d_waitMode = true"
              @endWaitingForEvent = "d_waitMode = false"
            >
              <template #briefing> </template>
            </round-launcher>
          </collapse-panel>

          <b-button @click="launch('CompetitionResults')" style="width:100%; height:3rem;" ><b-icon icon="trophee"/> Résultats de la compétition & classement général</b-button>
          <br/><br/>
          <b-button @click="showDump = true" :disabled="showDump" style="height:2rem;>">Dump des saisies BDD locale</b-button>
          <b-button @click="showDump = false" v-if="showDump" style="height:2rem;>">Masquer le dump</b-button>
          <scores-dump :competition_id="currentCompetition.id" :show="showDump"></scores-dump>
          <b-button @click="showDump = false" v-if="showDump" style="height:2rem;>">Masquer le dump</b-button>
        </b-container>
        <events-launcher-dashboard v-if="false"/>
    </div>
</template>

<script>
import CompetitionHeader from './components/CompetitionHeader.vue';
import EventsLauncherDashboard from '@/components/EventsLauncherDashboard.vue';
import CollapsePanel  from '@/components/Panels/CollapsePanel.vue';
import RoundLauncher from './components/Launchers/RoundLauncher.vue';
import ServerNotResponding from '../../components/Panels/ServerNotResponding.vue';
import LocalDataOnlyInfoPanel from '../../components/Panels/Info/LocalDataOnly.vue';
import roundsConfigurationHelper from '@/services/helpers/roundsConfigurationHelper';
import ScoresDump from './components/scoresDump.vue';

export default {
    components: { CompetitionHeader, EventsLauncherDashboard, CollapsePanel, RoundLauncher, ServerNotResponding, LocalDataOnlyInfoPanel, ScoresDump },
    data(){
      return {
        d_rounds: [],
        d_waitMode: false,
        showDump: false
      };
    },
    methods:{
      async launch(path){
        await this.$showLoader();
        this.d_waitMode = false;
        setTimeout(this.$router.push({name: path}), 2000);
      },
      async refresh(){
        await this.$showLoader();
        await roundsConfigurationHelper.refreshRoundsStatusesAsync(this.currentCompetition.id);    
        this.$hideLoader(); 
      }
    },
    async mounted(){
      await this.refresh();
      this.$hideLoader();
    }

}
</script>
